export default [
  {
    parent_class: 'Pro人群',
    class: '人生赢家',
    list: '40岁以上（均龄41.8），已婚为主，月收入18w以上，高端生活、豪华品质',
    score: '活力/探索',
    rank: '探寻未知世界，极限快感'
  },
  {
    parent_class: 'Pro人群',
    class: '人生赢家',
    list: '40岁以上（均龄41.8），已婚为主，月收入18w以上，高端生活、豪华品质',
    score: '享乐/释放',
    rank: '释放内心欲望，积极享受'
  },
  {
    parent_class: 'Pro人群',
    class: '人生赢家',
    list: '40岁以上（均龄41.8），已婚为主，月收入18w以上，高端生活、豪华品质',
    score: '能力/地位',
    rank: '表达成功自我，渴望赞美'
  },
  {
    parent_class: 'Pro人群',
    class: '高知名流',
    list: '35岁以上（均龄40.1），已婚为主，月收入6w以上，生活达人、丰富惬意',
    score: '理性/控制',
    rank: '回归内心理性，克制欲望'
  },
  {
    parent_class: 'Pro人群',
    class: '高知名流',
    list: '35岁以上（均龄40.1），已婚为主，月收入6w以上，生活达人、丰富惬意',
    score: '活力/探索',
    rank: '探寻未知世界，极限快感'
  },
  {
    parent_class: 'Pro人群',
    class: '高知名流',
    list: '35岁以上（均龄40.1），已婚为主，月收入6w以上，生活达人、丰富惬意',
    score: '能力/地位',
    rank: '表达成功自我，渴望赞美'
  },
  {
    parent_class: 'Pro人群',
    class: '潮流精英',
    list: '25-44岁（均龄37.1），已婚为主，月收入4w以上，小资生活、潮流品牌',
    score: '活力/探索',
    rank: '探寻未知世界，极限快感'
  },
  {
    parent_class: 'Pro人群',
    class: '潮流精英',
    list: '25-44岁（均龄37.1），已婚为主，月收入4w以上，小资生活、潮流品牌',
    score: '舒适/安全',
    rank: '希望呵护关怀，放松安宁'
  },
  {
    parent_class: 'Pro人群',
    class: '潮流精英',
    list: '25-44岁（均龄37.1），已婚为主，月收入4w以上，小资生活、潮流品牌',
    score: '个性/独特',
    rank: '凸显与众不同，优越突出'
  },
  {
    parent_class: 'Pro人群',
    class: '都市新中产',
    list: '35岁以下（均龄34.2），未婚为主，月收入2-6w，新潮娱乐、品质多元',
    score: '享乐/释放',
    rank: '释放内心欲望，积极享受'
  },
  {
    parent_class: 'Pro人群',
    class: '都市新中产',
    list: '35岁以下（均龄34.2），未婚为主，月收入2-6w，新潮娱乐、品质多元',
    score: '融合/沟通',
    rank: '分享欢愉快乐，和睦融入'
  },
  {
    parent_class: 'Pro人群',
    class: '都市新中产',
    list: '35岁以下（均龄34.2），未婚为主，月收入2-6w，新潮娱乐、品质多元',
    score: '活力/探索',
    rank: '探寻未知世界，极限快感'
  },
  {
    parent_class: 'Pro人群',
    class: '上进新生代',
    list: '35-55岁（均龄37.7），已婚为主，月收入4w以上，行动积极、生活平衡',
    score: '活力/探索',
    rank: '探寻未知世界，极限快感'
  },
  {
    parent_class: 'Pro人群',
    class: '上进新生代',
    list: '35-55岁（均龄37.7），已婚为主，月收入4w以上，行动积极、生活平衡',
    score: '理性/控制',
    rank: '回归内心理性，克制欲望'
  },
  {
    parent_class: 'Pro人群',
    class: '上进新生代',
    list: '35-55岁（均龄37.7），已婚为主，月收入4w以上，行动积极、生活平衡',
    score: '舒适/安全',
    rank: '希望呵护关怀，放松安宁'
  },
  {
    parent_class: 'Pro人群',
    class: '稳健实用派',
    list: '35-55岁（均龄38.0），已婚为主，月收入4w以上，稳健实用、生活闲适',
    score: '理性/控制',
    rank: '回归内心理性，克制欲望'
  },
  {
    parent_class: 'Pro人群',
    class: '稳健实用派',
    list: '35-55岁（均龄38.0），已婚为主，月收入4w以上，稳健实用、生活闲适',
    score: '舒适/安全',
    rank: '希望呵护关怀，放松安宁'
  },
  {
    parent_class: 'Pro人群',
    class: '稳健实用派',
    list: '35-55岁（均龄38.0），已婚为主，月收入4w以上，稳健实用、生活闲适',
    score: '顺从/归属',
    rank: '寻找群体归属，从众和谐'
  },
  {
    parent_class: 'Plus人群',
    class: '富足中年',
    list: '35-55岁（均龄37.5），已婚为主，月收入2-6w，健康生活、高质享受',
    score: '舒适/安全',
    rank: '希望呵护关怀，放松安宁'
  },
  {
    parent_class: 'Plus人群',
    class: '富足中年',
    list: '35-55岁（均龄37.5），已婚为主，月收入2-6w，健康生活、高质享受',
    score: '理性/控制',
    rank: '回归内心理性，克制欲望'
  },
  {
    parent_class: 'Plus人群',
    class: '富足中年',
    list: '35-55岁（均龄37.5），已婚为主，月收入2-6w，健康生活、高质享受',
    score: '享乐/释放',
    rank: '释放内心欲望，积极享受'
  },
  {
    parent_class: 'Plus人群',
    class: '社会新贵',
    list: '25-44岁（均龄36.5），已婚为主，月收入1.2-4w，品质格调、娱乐空间',
    score: '活力/探索',
    rank: '探寻未知世界，极限快感'
  },
  {
    parent_class: 'Plus人群',
    class: '社会新贵',
    list: '25-44岁（均龄36.5），已婚为主，月收入1.2-4w，品质格调、娱乐空间',
    score: '享乐/释放',
    rank: '释放内心欲望，积极享受'
  },
  {
    parent_class: 'Plus人群',
    class: '社会新贵',
    list: '25-44岁（均龄36.5），已婚为主，月收入1.2-4w，品质格调、娱乐空间',
    score: '舒适/安全',
    rank: '希望呵护关怀，放松安宁'
  },
  {
    parent_class: 'Plus人群',
    class: '时尚青年',
    list: '35岁以下（均龄33.4），未婚为主，月收入2w以下，超前享受，时尚舒适',
    score: '享乐/释放',
    rank: '释放内心欲望，积极享受'
  },
  {
    parent_class: 'Plus人群',
    class: '时尚青年',
    list: '35岁以下（均龄33.4），未婚为主，月收入2w以下，超前享受，时尚舒适',
    score: '活力/探索',
    rank: '探寻未知世界，极限快感'
  },
  {
    parent_class: 'Plus人群',
    class: '时尚青年',
    list: '35岁以下（均龄33.4），未婚为主，月收入2w以下，超前享受，时尚舒适',
    score: '个性/独特',
    rank: '凸显与众不同，优越突出'
  },
  {
    parent_class: 'Plus人群',
    class: '乐享安居派',
    list: '25-34岁（均龄35.9），已婚为主，月收入8k以下/1.2-4w，高性价比、精致闲适',
    score: '享乐/释放',
    rank: '释放内心欲望，积极享受'
  },
  {
    parent_class: 'Plus人群',
    class: '乐享安居派',
    list: '25-34岁（均龄35.9），已婚为主，月收入8k以下/1.2-4w，高性价比、精致闲适',
    score: '融合/沟通',
    rank: '分享欢愉快乐，和睦融入'
  },
  {
    parent_class: 'Plus人群',
    class: '乐享安居派',
    list: '25-34岁（均龄35.9），已婚为主，月收入8k以下/1.2-4w，高性价比、精致闲适',
    score: '舒适/安全',
    rank: '希望呵护关怀，放松安宁'
  },
  {
    parent_class: 'Plus人群',
    class: '干练优薪族',
    list: '35岁以下（均龄34.1），未婚为主，月收入1.2w以下，快节奏轻休闲、高效居家品质',
    score: '活力/探索',
    rank: '探寻未知世界，极限快感'
  },
  {
    parent_class: 'Plus人群',
    class: '干练优薪族',
    list: '35岁以下（均龄34.1），未婚为主，月收入1.2w以下，快节奏轻休闲、高效居家品质',
    score: '融合/沟通',
    rank: '分享欢愉快乐，和睦融入'
  },
  {
    parent_class: 'Plus人群',
    class: '干练优薪族',
    list: '35岁以下（均龄34.1），未婚为主，月收入1.2w以下，快节奏轻休闲、高效居家品质',
    score: '理性/控制',
    rank: '回归内心理性，克制欲望'
  },
]
